import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Working within the mental health field is incredibly important to me. I want to be someone who people can rely on and  provide support, help, and guidance to all who need it. Before transitioning into this field, I taught ESL and I have a deep appreciation for teaching. I have a degree from PSU in Global Studies and I love language acquisition. I’ve studied Korean, German, and French, but I always want to learn more.
        </p>
        <p>
         Currently, I’m pursuing a post-bac in Psychology at Oregon State University with the goal of expanding my ability to help others in the mental health field. When I’m not at work, I enjoy spending time with my friends and family – especially going on hikes to explore the natural wonders of the Pacific Northwest.
        </p>
        <p>
         Looking forward to connecting with you soon!
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
