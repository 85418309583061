import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I am tenacious in everything that I do, and I love people! This combination has given me well over 15 years of customer service experience. From working as a Fraud prevention investigator, to being a receptionist for a small business in HVAC.  I am a Utah State University graduate with a bachelor's degree in Gender Studies and Human Services Science, along with a minor in Family and Human Developoment. I put my whole heart into my work for others and want to help as much as possible!
        </p>
        <p>
         When I'm not working, I enjoy spending time with my family, working out at the gym, and learning new skills in art! One of my go-to projects is creating handmade mandala and zentangle designs, then gifting them to my friends to color and enjoy. 
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
