import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Counselors from "./pages/Counselors";
import Staff from "./pages/Staff";
import Locations from "./pages/Locations";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/locations" element = {<Locations />} />
        <Route path="/counselors" element={<Counselors />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;