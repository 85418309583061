import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        Are you feeling stuck in unhelpful thinking patterns? Maybe your roles as a partner, parent, friend, or family member are feeling uncertain. I enjoy working with people who are feeling at odds with things as they are. Things might need to change, and change can be hard and scary. I’m excited for the opportunity to walk alongside you to support the growth you believe you want in your life. 
        </p>
        <p>
        I value a person’s ability to be the narrator and take ownership of their story. I am passionate about working with children, adolescents and adults related to many issues, including foster care and adoption, identity, loss, grief, anxiety and depression. I have experience working with parents who are challenged by their role and their children’s behaviors, as well as families who are in conflict. I strive to be an ally to BIPOC and LGBTQIA2S+ folks.
        </p>
        <p>
        I work within the theoretical frameworks of Attachment Theory, Family and Ecological Systems Theory. I utilize Cognitive Behavioral Therapy and Solution-Focused Therapy in my practice, and am a Strengths-based, Person-centered clinician.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
